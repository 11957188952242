<template>
  <div id="evaluate">
    <div class="evaluate_box">
      <div class="title">价值评估</div>
      <div class="evaluate-number clearfix">
        <div class="circle pull-left">
          <canvas id="chart" style="height: 200px;width: 200px;background-color: white" width="200" height="200"
                  data-fljz="0" data-jjjz="0" data-jsjz="0"></canvas>
          <div class="evaluate-point">
            <p>0.00</p>
            <p>价值评估</p>
          </div>
        </div>
        <ul class="pull-right">
          <li class="clearfix">
            <span></span>
            <span>法律价值</span>
            <span>{{report.labels.legalValue || '0.00'}}分</span>
          </li>
          <li class="clearfix">
            <span></span>
            <span>经济价值</span>
            <span>{{report.labels.economicValue || '0.00'}}分</span>
          </li>
          <li class="clearfix">
            <span></span>
            <span>技术价值</span>
            <span>{{report.labels.technologicalValue || '0.00'}}分</span>
          </li>
        </ul>
      </div>
      <div class="evaluate-table">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th colspan="2">
              法律价值
            </th>
          </tr>
          <tr>
            <td>
              依懒性
            </td>
            <td>
              <span>非专利依懒性: {{report.labels.nonPatentDependence || '-'}}</span>
              <span>专利依懒性: {{report.labels.patentDependence || '-'}}</span>
            </td>
          </tr>
          <tr>
            <td>
              专利宽度
            </td>
            <td>
              <span>地域保护范围: {{report.labels.gengraphicalCoverage || '-'}}</span>
              <span>权利保护范围: {{report.labels.scopeOfRightProtection || '-'}}</span>
            </td>
          </tr>
          <tr>
            <td>
              专利稳定性
            </td>
            <td>
              <span>法律地位稳固度: {{report.labels.scopeOfRightProtection || '-'}}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th colspan="2">
              经济价值
            </th>
          </tr>
          <tr>
            <td>
              市场竞争力
            </td>
            <td>
              <span>市场竞争得分: {{report.labels.marketCompetitiveness || '-'}}</span>
            </td>
          </tr>
          <tr>
            <td>
              专利布局
            </td>
            <td>
              <span>专利族地域分布: {{report.labels.patenteGeographuicalDistribution || '-'}}</span>
              <span>专利族规模: {{report.labels.patentFamilySize || '-'}}</span>
            </td>
          </tr>
          <tr>
            <td>
              专利经济寿命
            </td>
            <td>
              <span>专利时限: {{report.labels.timeLimitOfPatent || '-'}}</span>
              <span>专利维持状态: {{report.labels.patentMaintenance || '-'}}</span>
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th colspan="2">
              技术价值
            </th>
          </tr>
          <tr>
            <td>
              技术可替代性
            </td>
            <td>
              <span>专利新颖度: {{report.labels.patentNovelty || '-'}}</span>
            </td>
          </tr>
          <tr>
            <td>
              技术先进性
            </td>
            <td>
              <span>技术交叉性: {{report.labels.technicalCrossover || '-'}}</span>
              <span>科学关联强度: {{report.labels.scientificCorrelationStrength || '-'}}</span>
              <span>团队影响力: {{report.labels.groupInfluence || '-'}}</span>
            </td>
          </tr>
          <tr>
            <td>
              技术应用范围
            </td>
            <td>
              <span>技术覆盖度: {{report.labels.technicalCoverage || '-'}}</span>
              <span>技术专业度: {{report.labels.technicalDegree || '-'}}</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="title">标定说明</div>
      <div class="calibration-table">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>
              法律价值
            </th>
          </tr>
          <tr>
            <td>
              {{report.evaluation.fljz || '-'}}
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>
              经济价值
            </th>
          </tr>
          <tr>
            <td>
              {{report.evaluation.jjjz || '-'}}
            </td>
          </tr>
          </tbody>
        </table>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>
              技术价值
            </th>
          </tr>
          <tr>
            <td>
              {{report.evaluation.jsjz || '-'}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="title">标定信息</div>
      <div class="calibration-info">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td>专利名</td>
            <td>
              <div class="ellipsis" title="-">
                {{report.patent.ti || '-'}}
              </div>
            </td>
            <td>
              专利申请日
            </td>
            <td class="datetime" data-datetime="-" data-format="YYYY-MM-DD">
              {{report.patent.ad || '-'}}
            </td>
            <td>
              同族专利项
            </td>
            <td>
              {{report.patent.siblingPatent || '-'}}
            </td>
          </tr>
          <tr>
            <td>独权数量</td>
            <td>
              {{report.labels.exclusiveRightNum || '-'}}
            </td>
            <td>
              专利类型
            </td>
            <td>
              {{report.patent.type || '-'}}
            </td>
            <td>
              颁证日
            </td>
            <td class="datetime" data-datetime="-" data-format="YYYY-MM-DD">
              {{report.patent.pd || '-'}}
            </td>
          </tr>
          <tr>
            <td>发明人</td>
            <td>
              <div class="ellipsis" title="-">
                {{report.patent.pin || '-'}}
              </div>
            </td>
            <td>
              无效次数
            </td>
            <td>
              {{report.labels.invalidNum || '-'}}
            </td>
            <td>
              申请号(专利号)
            </td>
            <td>
              {{report.patent.an || '-'}}
            </td>
          </tr>
          <tr>
            <td>进入国家阶段</td>
            <td class="datetime" data-datetime="-" data-format="YYYY-MM-DD">
              {{report.patent.PCTEnterDate || '-'}}
            </td>
            <td>
              从属专利数量
            </td>
            <td>
              --
            </td>
            <td>
              技术专利契合度
            </td>
            <td>
              --
            </td>
          </tr>
          <tr>
            <td>公开号</td>
            <td>
              {{report.patent.pnm|| '-'}}
            </td>
            <td>
              地址
            </td>
            <td>
              <div class="ellipsis" title="-">
                {{report.labels.address|| '-'}}
              </div>
            </td>
            <td>
              独权长度
            </td>
            <td>
              {{report.labels.exclusiveRightNum|| '-'}}
            </td>
          </tr>
          <tr>
            <td>关键词</td>
            <td>
              {{report.labels.keyword3|| '-'}}
            </td>
            <td>
              国省代码
            </td>
            <td>
              {{report.labels.provinceCode|| '-'}}
            </td>
            <td>
              发明人数量
            </td>
            <td>
              {{report.labels.inventorNum|| '-'}}
            </td>
          </tr>
          <tr>
            <td>保护年限</td>
            <td>
              {{report.labels.durationOfPatent|| '-'}}
            </td>
            <td>
              权利要求修改幅度
            </td>
            <td>
              {{report.labels.durationOfPatent|| '-'}}
            </td>
            <td>
              主分类号
            </td>
            <td>
              {{report.patent.pic|| '-'}}
            </td>
          </tr>
          <tr>
            <td>决定次数</td>
            <td>
              {{report.labels.decisionNum|| '-'}}
            </td>
            <td>
              剩余有效期
            </td>
            <td>
              {{report.labels.remainingValidPeriod|| '-'}}
            </td>
            <td>
              引证专利数量
            </td>
            <td>
              {{report.labels.citedPatentNum|| '-'}}
            </td>
          </tr>
          <tr>
            <td>分类号</td>
            <td>
              <div class="ellipsis" title="-">
                {{report.patent.sic|| '-'}}
              </div>
            </td>
            <td>
              再审次数
            </td>
            <td>
              {{report.labels.retrialTimes|| '-'}}
            </td>
            <td>
              异议次数
            </td>
            <td>
              {{report.labels.objectionNum|| '-'}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="title">引证信息</div>
      <div class="citation-info quote-info">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>申请号</th>
            <th>被引证文献公布号</th>
            <th>被引证文<br/>献公布日</th>
            <th>公开号</th>
            <th> 公开日</th>
            <th> 被引证文献名称</th>
            <th> 被引证文<br/>献申请人</th>
            <th> 被引证<br/>文献国别</th>
          </tr>
          <tr v-for="(item,index) in report.sqryzzlList" :key="index">
            <td>{{item.appNo || '-'}}</td>
            <td>{{item.pubNo || '-'}}</td>
            <td>{{item.announcementDate || '-'}}</td>
            <td>{{item.publicNo || '-'}}</td>
            <td>{{item.publicDay || '-'}}</td>
            <td>
              <div class="ellipsis" :title="item.title">
                {{item.title || '-'}}
              </div>
            </td>
            <td>{{item.assigneesName[0] || '-'}}</td>
            <td>{{item.country || '-'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="title">相似度分析</div>
      <div class="citation-info similar-info">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>公开号</th>
            <th>申请号</th>
            <th>名称</th>
            <th>申请人</th>
            <th>相似度</th>
          </tr>
          <tr v-for="(item,index) in report.similarList" :key="index">
            <td>{{item.pubNo || '-'}}</td>
            <td>{{item.appNo || '-'}}.0</td>
            <td>
              <div class="ellipsis" :title="item.title">
                {{item.title || '-'}}
              </div>
            </td>
            <td>{{item.assigneesName[0] || '-'}}</td>
            <td>{{item.score || '-'}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "report",
    data() {
      return {}
    },
    props: ['report']
  }
</script>

<style scoped>
  @import "~@/assets/css/style.css";

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  tr {
    line-height: 50px;
  }

  td, th {
    line-height: 30px;
    padding: 10px 0;
  }

  tr, td, th {
    border: 1px solid #ddd;
  }
</style>
